<template>
	<div class="address">
		<div class="title">
			<div class="">
				{{i18n('23')}}
			</div>
			<div class="right" v-if="!noData" @click="add(1)">
				{{i18n('44')}}
			</div>
		</div>
		<div class="inner">
			<locationItem v-for="(item,index) in listData" :key="index" :data='item'
				@del="del(item,index)" @edit="editOpen"></locationItem>
				<!-- @defaultChange="defaultChange(index)" @del="del(item,index)" @edit="edit"></locationItem> -->
				
			<div class="noData" v-if="noData">
				<div class="">
					<img src="@/assets/noData3.png" alt="">
					<div class="text">
						{{i18n('228')}}
					</div>
				</div>
			</div>	
			<div class="but" v-if="noData" @click="add(1)">
				{{i18n('229')}}
			</div>
		</div>
		<add ref="add" @czSuccess="list"></add>
	</div>
</template>

<script>
	import {
		list,
		del
	} from "@/api/address.js"

	import locationItem from "./components/locationItem.vue"
	import add from "./components/add.vue"
	export default {
		name: "address",
		components: {
			locationItem,
			add
		},
		data() {
			return {
				listData: [],
				noData:false
			}
		},
		methods: {
			async list() {
				let {
					data
				} = await list()
				this.listData = data
				if(this.listData && this.listData.length>0){
					this.noData = false
				}else{
					this.noData = true
				}
			},
			// async defaultChange(index) {
			// 	if (this.listData[index].is_default == 0) {
			// 		this.listData[index].is_default = 1
			// 	} else {
			// 		this.listData[index].is_default = 0
			// 	}
			// 	await edit(this.listData[index])
			// 	this.list()
			// },
			async del(item, index) {
				await del({
					id: item.id
				})
				this.listData.splice(index, 1)
			},
			editOpen(id){
				this.add(2,id)
			},
			add(type,id){
				this.$refs.add.open(type,id)
			}
		},
		computed: {
			userInfo() {
				return this.$store.state.user.userInfo
			}
		},
		created() {
			this.list()
		},
		mounted() {

		}
	};
</script>

<style scoped>
	.but{
		width: 310px;
		height: 44px;
		background: #56AB7B;
		border-radius: 22.5px;
		font-family: PingFangSC-Medium;
		font-size: 18px;
		color: #FFFFFF;
		text-align: center;
		font-weight: 500;
		line-height: 44px;
		margin: 0 auto;
		cursor: pointer;
	}
	.noData{
		width: 100%;
		height: 290px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.noData img{
		width: 100px;
		height: 100px;
		display: block;
		margin: 0 auto;
	}
	.noData .text{
		font-family: PingFangSC-Medium;
		font-size: 14px;
		color: #1D2129;
		text-align: center;
		font-weight: 500;
		margin-top: 24px;
	}
	.title {
		display: flex;
		align-items: center;
		justify-content: space-between;
		height: 69px;
		font-family: PingFangSC-Medium;
		font-size: 18px;
		color: #1D2129;
		line-height: 69px;
		font-weight: 500;
	}

	.title .right {
		height: 32px;
		background: #56AB7B;
		border-radius: 22.5px;
		font-family: PingFangSC-Medium;
		font-size: 18px;
		color: #FFFFFF;
		text-align: center;
		font-weight: 500;
		padding: 0 26px;
		line-height: 32px;
		cursor: pointer;
	}

	.inner {
		flex: 1;
		overflow-y: scroll;
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-content: baseline;
		flex-wrap: wrap;
	}

	.address{
		width: 100%;
		display: flex;
		flex-direction: column;
		height: 100%;
	}
</style>
