<template>
	<div class="locationItem">
		<div class="top">
			<div class="info">
				<div class="name item">
					<div class="label">
						{{i18n('65')}}：
					</div>
					{{data.name}}
				</div>
				<div class="phone item">
					<div class="label">
						{{i18n('47')}}：
					</div>
					{{phone}}
				</div>
			</div>
			<div class="bq" v-if="data.label==0">
				{{i18n('51')}}
			</div>
			<div class="bq" v-if="data.label==1">
				{{i18n('52')}}
			</div>
			<div class="bq" v-if="data.label==2">
				{{i18n('53')}}
			</div>
		</div>
		<div class="bot">
			<div class="" style="display: flex;">
				<div class="label">
					{{i18n('66')}}：
				</div>
				<div class="loca">
					{{data.city}}-{{data.detail_address}}
				</div>
			</div>
			
			<div class="edit" @click="edit">
				{{i18n('67')}}
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "locationItem",
		props:['data'],
		data() {
			return {
				
			}
		},
		methods: {
			edit(){
				this.$emit("edit",this.data.id)
			}
		},
		computed:{
			phone(){
				if(this.data){
					return this.data.phone.substring(2)
				}else{
					return ''
				}
			}
		},
		created() {
			
		},
		mounted() {
			
		}
	};
</script>

<style scoped>
	.bot{
		height: 70px;
		display: flex;
		justify-content: space-between;
	}
	.loca{
		font-family: PingFangSC-Regular;
		font-size: 14px;
		color: #86909C;
		line-height: 22px;
		font-weight: 400;
		width: 300px;
	}
	.edit{
		font-family: PingFangSC-Regular;
		font-size: 14px;
		color: #56AB7B;
		font-weight: 400;
		display: flex;
		align-items: flex-end;
		cursor: pointer;
	}
	.top{
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 26px;
	}
	.label{
		width: 82px;
		font-family: PingFangSC-Regular;
		font-size: 14px;
		color: #86909C;
		font-weight: 400;
	}
	.top .info{
		display: flex;
		align-items: center;
	}
	.top .info .name{
		width: 210px;
	}
	.top .item{
		font-family: PingFangSC-Regular;
		font-size: 14px;
		color: #86909C;
		line-height: 22px;
		font-weight: 400;
		display: flex;
		align-items: center;
	}
	.top .info .phone .label{
		width: auto;
		padding-right: 12px;
	}
	.bq{
		font-family: PingFangSC-Regular;
		font-size: 14px;
		color: #1D2129;
		line-height: 24px;
		font-weight: 400;
		padding: 0 10px;
		background: rgba(86, 171, 123, 0.10);
		border-radius: 4px;
	}
	.locationItem{
		width: 504px;
		height: 156px;
		background: #FFFFFF;
		border-radius: 12px;
		padding: 24px 24px 0 24px;
		box-sizing: border-box;
		margin-bottom: 16px;
	}
</style>